import { bindable, inject } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { FormSchema }       from 'modules/administration/tree-modal-multiselect/form-schema';
import { DialogController } from 'aurelia-dialog';

@inject(AppContainer, FormSchema, DialogController)
export class TreeModalMultiselect {

    @bindable modalSettings = {
        title:   'form.title.tree',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param dialogController
     */
    constructor(appContainer, formSchema, dialogController) {
        this.appContainer     = appContainer;
        this.formSchema       = formSchema;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.model                  = params.model;
        this.key                    = params.key;
        this.label                  = params.label;
        this.settings               = params.settings;
        this.remoteSource           = params.remoteSource;
        this.remoteSourceParameters = params.remoteSourceParameters;

        this.schema = this.formSchema.schema(this);
    }

}
