import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { GlobalConfig }     from 'resources/configs/global-config';

@inject(CustomHttpClient)
export class FilesRepository {

    baseUri = '';

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;
        this.downloader = downloader;
    }

    /**
     * Returns upload URI
     *
     * @returns {*}
     */
    uploadUri() {
        return GlobalConfig.generateApiUrl(this.baseUri + 'files/upload');
    }

    /**
     * Performs a search given some criteria
     *
     * @param relatableId
     * @param criteria
     *
     * @returns {*}
     */
    search(relatableId, criteria) {
        return this.httpClient.post(this.baseUri + relatableId + '/files/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param relatableId
     * @param data
     *
     * @returns {*}
     */
    create(relatableId, data) {
        return this.httpClient.post(this.baseUri + relatableId + '/files', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(this.baseUri + 'files/' + id);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(this.baseUri + 'files/' + id, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(this.baseUri + 'files/' + id);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete(this.baseUri + 'files/destroy-selected', {ids: ids});
    }

    /**
     * Downloads a record
     *
     * @param id
     *
     * @returns {*}
     */
    download(id) {
        return this.httpClient.rawGet(this.baseUri + 'files/' + id + '/preview')
            .then(response => response.blob());
    }
}
