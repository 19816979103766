import { bindable, inject }                from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseViewModel }                   from 'base-view-model';
import { FilesRepository }                 from 'modules/administration/files/services/repository';
import { FormSchema }                      from 'modules/administration/files/form-schema';
import { Downloader }                      from 'resources/services/downloader';
import { DialogController, DialogService } from 'aurelia-dialog';

@inject(AppContainer, FilesRepository, FormSchema, Downloader, DialogService, DialogController)
export class ListFilesModal extends BaseViewModel {

    listingId = 'files-listing-' + Math.floor(Math.random() * 65000);
    formId    = 'files-creation-form-' + Math.floor(Math.random() * 65000);

    @bindable settings = {
        size:    'modal-full',
        buttons: false,
    };

    readonly          = false;
    formSchemaVisible = false;

    relatableRepository;
    relatableModel;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param downloader
     * @param dialogController
     * @param dialogService
     */
    constructor(appContainer, repository, formSchema, downloader, dialogService, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.formSchema       = formSchema;
        this.downloader       = downloader;
        this.dialogService    = dialogService;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        super.activate();

        Object.assign(this.settings, params.settings);

        this.readonly            = params.readonly === true;
        this.relatableModel      = params.relatableModel;
        this.relatableRepository = params.relatableRepository;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.settings.title = this.appContainer.i18n.tr('form.title.identified-files-listing', {
            description: this.relatableModel[this.relatableModelNameField],
        });
    }

}
