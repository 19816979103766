import { BaseModel } from 'modules/core/models/base-model';

const NO  = 0;
const YES = 1;

export class BooleanOption extends BaseModel {

    static get YES() {
        return YES;
    }

    static get NO() {
        return NO;
    }

}
