import { inject } from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AccountActivationsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Sends the user's account activation email.
     *
     * @param userId
     *
     * @returns {*}
     */
    sendEmail(userId) {
        return this.httpClient.get(`administration/users/account-activations/${userId}/send-email`);
    }

}
