import { AutoLoginToken } from 'modules/administration/models/auto-login-token';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {AutoLoginToken}
     */
    model() {
        let model = new AutoLoginToken();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     */
    schema(viewModel) {
        this.limit_to_ip = {
            type:     'text',
            key:      'limit_to_ip',
            label:    'form.field.limit-to-ip',
            size:     12,
            required: false,
        };

        return [
            [this.limit_to_ip],
        ];
    }

}
