import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { FormSchema }                    from 'modules/administration/non-sequential-dates/form-schema';
import { DangerAlertMessage }            from '../../../resources/services/danger-alert-message';

@inject(AppContainer, FormSchema)
export class NonSequentialDatesCustomElement extends BaseFormViewModel {

    @bindable({defaultBindingMode: bindingMode.twoWay}) records;

    @bindable readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     */
    constructor(appContainer, formSchema) {
        super(appContainer);

        this.formSchema = formSchema;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();

        this.initialModel = this.formSchema.model(this);
        this.model        = this.formSchema.model(this);

        this.schema = this.formSchema.schema(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search:  () => Promise.resolve({
                    data:            this.records,
                    recordsTotal:    this.records.length,
                    recordsFiltered: this.records.length,
                }),
                destroy: null,
            },
            actions:             [
                {
                    icon:    'icon-trash',
                    visible: () => !this.readonly,
                    action:  (row, $index) => this.records.splice($index, 1),
                    tooltip: 'form.button.delete',
                },
            ],
            options:             [],
            buttons:             [],
            selectable:          false,
            showOptionsDropdown: false,
            showInfo:            false,
            showPagination:      false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:  'date',
                    name:  'conformity_evaluation_expected_dates.date',
                    title: 'form.field.date',
                },
                {
                    data:  'period_name',
                    name:  'periods.name',
                    title: 'form.field.period',
                },
                {
                    data:  'local',
                    name:  'conformity_evaluation_expected_dates.local',
                    title: 'form.field.local',
                },
                {
                    data:  'observations',
                    name:  'conformity_evaluation_expected_dates.observations',
                    title: 'form.field.observations',
                },
            ],
        };
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        if (this.model.date && isNumeric(this.model.period_id)) {
            this.pushNewRecord(this.model);
            this.resetForm();
        } else {
            this.alert = new DangerAlertMessage(
                this.appContainer.i18n.tr('validation.message.invalid-record'),
                `<li>${this.appContainer.i18n.tr('validation.message.ensure-required-fields-are-filed-in')}</li>`,
            );
        }
    }

    /**
     * Pushes new record
     *
     * @param model
     */
    pushNewRecord(model) {
        let tempModel = this.formSchema.model(this);

        tempModel.assign(model);

        // TODO - THINK OF A BETTER WAY TO DO THIS! MAYBE ASK BACKEND?
        tempModel.period_name = this.dropdownSelectedText('non_sequential_date_period_id');

        this.records.push(tempModel);
    }

    /**
     * Returns dropdown selected text
     *
     * @param field
     *
     * @returns {*|jQuery}
     */
    dropdownSelectedText(field) {
        return $('#' + field + ' option:selected').text();
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.formSchema.model(this));

                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

}
