import { inject }            from 'aurelia-framework';
import { NonSequentialDate } from 'modules/administration/models/non-sequential-date';
import { PeriodsRepository } from 'modules/administration/periods/services/repository';

@inject(PeriodsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param periodsRepository
     */
    constructor(periodsRepository) {
        this.periodsRepository = periodsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {NonSequentialDate}
     */
    model() {
        let model = new NonSequentialDate();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.date = {
            key:   'date',
            id:    'non_sequential_date_date',
            type:  'material-ui-date-picker',
            label: 'form.field.date',
            size:  6,
        };

        this.period_id = {
            type:         'select2',
            key:          'period_id',
            id:           'non_sequential_date_period_id',
            label:        'form.field.period',
            size:         6,
            remoteSource: this.periodsRepository.active.bind(this.periodsRepository),
            required:     false,
        };

        this.local = {
            type:     'text',
            key:      'local',
            id:       'non_sequential_date_local',
            label:    'form.field.local',
            size:     12,
            required: false,
        };

        this.observations = {
            type:     'textarea',
            key:      'observations',
            id:       'non_sequential_date_observations',
            label:    'form.field.observations',
            size:     12,
            required: false,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.date, this.period_id],
            [this.local],
            [this.observations],
            [this.buttons],
        ];
    }

}
