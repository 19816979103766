import { inject }                         from 'aurelia-framework';
import { BooleanStatus }                  from 'modules/administration/models/boolean-status';
import { CompanyOrganization }            from 'modules/administration/models/company-organization';
import { CompanyOrganizationsRepository } from 'modules/administration/company-organizations/services/repository';
import { BooleanStatusesRepository }      from 'modules/administration/boolean-statuses/services/repository';
import { ClientsRepository }              from 'modules/wemake/clients/services/repository';
import { TreeModal }                      from 'modules/administration/tree-modal/index';
import { DialogService }                  from 'aurelia-dialog';

@inject(CompanyOrganizationsRepository, BooleanStatusesRepository, ClientsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param companyOrganizationsRepository
     * @param booleanStatusesRepository
     * @param clientsRepository
     * @param dialogService
     */
    constructor(companyOrganizationsRepository, booleanStatusesRepository, clientsRepository, dialogService) {
        this.companyOrganizationsRepository = companyOrganizationsRepository;
        this.booleanStatusesRepository      = booleanStatusesRepository;
        this.clientsRepository              = clientsRepository;
        this.dialogService                  = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {CompanyOrganization}
     */
    model() {
        let model = new CompanyOrganization();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        let isSystemReserved = viewModel.appContainer.authenticatedUser.isSystemReserved();

        this.acronym = {
            type:       'text',
            key:        'acronym',
            label:      'form.field.acronym',
            size:       2,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.parent_id = {
            type:         'select2',
            key:          'parent_id',
            label:        'form.field.branch',
            size:         4,
            remoteSource: this.companyOrganizationsRepository.active.bind(this.companyOrganizationsRepository),
            inputGroup:   {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModal,
                        model:     {
                            repository: {
                                method: 'tree',
                                source: this.companyOrganizationsRepository,
                            },
                            onClick:    (event, data) => {
                                viewModel.model.parent_id = data.node.key;
                            },
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.users = {
            type:       'users-duallistbox',
            key:        'users',
            label:      'form.field.users',
            size:       12,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.booleanStatusesRepository.active.bind(this.booleanStatusesRepository),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.company-organizations.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        let rows = [
            [this.acronym, this.name],
            [this.parent_id],
            [this.users],
            [this.status_id],
            [this.buttons],
        ];

        if (isSystemReserved) {
            this.client_id = {
                type:         'select2',
                key:          'client_id',
                label:        'form.field.client',
                size:         4,
                options:      [],
                remoteSource: this.clientsRepository.active.bind(this.clientsRepository),
                attributes:   {
                    disabled: true,
                },
            };

            rows[1].push(this.client_id);
        }

        return rows;
    }
}
