import { bindable, inject }             from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { AccountActivationsRepository } from 'modules/administration/account-activations/services/repository';
import { AppContainer }                 from 'resources/services/app-container';
import { BaseListViewModel }            from 'base-list-view-model';
import { UsersRepository }              from 'modules/administration/users/services/repository';
import { FilterFormSchema }             from 'modules/administration/users/filter-form-schema';
import { UserStatus }                   from 'modules/administration/models/user-status';
import { AutoLoginModal }               from 'modules/administration/users/auto-login/index';
import { DialogService }                from 'aurelia-dialog';

@inject(AppContainer, UsersRepository, FilterFormSchema, AccountActivationsRepository, DialogService)
export class ListUsers extends BaseListViewModel {

    listingId = 'administration-users-listing';

    @bindable headerTitle    = 'listing.administration.users';
    @bindable newRecordRoute = 'administration.users.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param accountActivationsRepository
     * @param dialogService
     */
    constructor(appContainer, repository, filterFormSchema, accountActivationsRepository, dialogService) {
        super(appContainer);

        this.repository                   = repository;
        this.filterFormSchema             = filterFormSchema;
        this.accountActivationsRepository = accountActivationsRepository;
        this.dialogService                = dialogService;

        this.newRecordRoute = appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.manage',
            'administration.users.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.administration.user',
            repository: this.repository,
            edit:       'administration.users.edit',
            destroy:    'administration.users.destroy',
            actions:    [
                {
                    visible: (row) => row.status.id === UserStatus.PENDING,
                    action:  (row) => this.sendAccountActivationEmail(row),
                    tooltip: 'form.button.resend-account-activation-email',
                    icon:    'icon-user-check',
                },
                {
                    visible: (row) => this.appContainer.authenticatedUser.hasCurrentTenant() && row.status.id === UserStatus.ACTIVE,
                    action:  (row) => this.dialogService.open({viewModel: AutoLoginModal, model: row}),
                    tooltip: 'form.button.auto-login',
                    icon:    'icon-certificate',
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'name',
                    name:  'users.name',
                    title: 'form.field.name',
                },
                {
                    data:  'email',
                    name:  'users.email',
                    title: 'form.field.email',
                },
                {
                    data:       'role',
                    name:       'roles.name',
                    title:      'form.field.roles',
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:       'client',
                    name:       'clients.name',
                    title:      'form.field.client',
                    display:    !this.appContainer.authenticatedUser.hasCurrentTenant(),
                    toggleable: !this.appContainer.authenticatedUser.hasCurrentTenant(),
                },
                {
                    data:            'created_at',
                    name:            'users.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'status',
                    name:  'user_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Sends the given user's account activation email.
     *
     * @param {id}
     */
    sendAccountActivationEmail({id}) {
        this.accountActivationsRepository
            .sendEmail(id)
            .then(response => {
                let notice = response.status === true ? 'successNotice' : 'dangerNotice';
                this.appContainer.notifier[notice](response.message);
            });
    }

}
