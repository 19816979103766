import { bindable, inject }     from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { BaseViewModel }        from 'base-view-model';
import { UserGroupsRepository } from 'modules/administration/user-groups/services/repository';

@inject(AppContainer, UserGroupsRepository)
export class ListUserGroups extends BaseViewModel {

    headerTitle = 'listing.administration.user-groups';
    listingId   = 'administration-user-groups-listing';

    @bindable newRecordRoute = 'administration.user-groups.create';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.user-groups.manage',
            'administration.user-groups.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.administration.user-group',
            repository: this.repository,
            edit:       null,
            destroy:    {
                visible: (row) => !row.isSticky || this.appContainer.authenticatedUser.isSystemReserved(),
                action:  'administration.user-groups.delete',
            },
            actions:    [
                {
                    tooltip: 'form.button.edit',
                    visible: (row) => !row.isSticky || this.appContainer.authenticatedUser.isSystemReserved(),
                    icon:    'icon-pencil7',
                    action:  (row) => this.redirectToRoute({
                        name:   'administration.user-groups.edit',
                        params: {id: row.id},
                    }),
                },
                {
                    tooltip: 'form.button.view',
                    visible: (row) => row.isSticky && !this.appContainer.authenticatedUser.isSystemReserved(),
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'administration.user-groups.view',
                        params: {id: row.id},
                    }),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'name',
                    name:  'user_groups.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
