import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class CompanyOrganizationsRepository {

    baseUrl = 'administration/company-organizations';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/company-organizations/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/company-organizations/active');
    }

    /**
     * Returns tree
     *
     * @returns {*}
     */
    tree(collapsed = false) {
        return this.httpClient.get('administration/company-organizations/tree' + ((collapsed === true) ? '?collapsed=true' : ''));
    }

    /**
     * Returns tree of a given client
     *
     * @param clientId
     * @param collapsed
     */
    treeByClient(clientId, collapsed = false) {
        return this.httpClient.get(`administration/company-organizations/tree/client/${clientId}${collapsed === true ? '?collapsed=true' : ''}`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/company-organizations/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('administration/company-organizations', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`administration/company-organizations/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`administration/company-organizations/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`administration/company-organizations/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('administration/company-organizations/destroy-selected', {ids: ids});
    }

}
