export class FormSchema {

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this[viewModel.key] = {
            type:                   'fancytree',
            key:                    viewModel.key,
            id:                     `${viewModel.key}_multiselect_fancytree`,
            label:                  viewModel.label,
            remoteSource:           viewModel.remoteSource,
            remoteSourceParameters: viewModel.remoteSourceParameters,
            settings:               viewModel.settings,
            size:                   12,
        };

        return [
            [this[viewModel.key]],
        ];
    }

}
