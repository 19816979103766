import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class UserGroupsRepository {

    baseUrl = 'administration/user-groups';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/user-groups/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/user-groups/active');
    }

    /**
     * Returns all records by a given client
     *
     * @returns {*}
     */
    allByClient(clientId) {
        return this.httpClient.get(`administration/user-groups/all/client/${clientId}`);
    }

    /**
     * Returns active records by a given client
     *
     * @returns {*}
     */
    activeByClient(clientId) {
        return this.httpClient.get(`administration/user-groups/active/client/${clientId}`);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/user-groups/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('administration/user-groups', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`administration/user-groups/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`administration/user-groups/${id}`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`administration/user-groups/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('administration/user-groups/destroy-selected', {ids: ids});
    }

}
