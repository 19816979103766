import { BaseModel } from 'modules/core/models/base-model';

const INACTIVE = 0;
const ACTIVE   = 1;

export class BooleanStatus extends BaseModel {

    static get ACTIVE() {
        return ACTIVE;
    }

    static get INACTIVE() {
        return INACTIVE;
    }

}
