import { BaseModel } from 'modules/core/models/base-model';

export class NonSequentialDate extends BaseModel {

    date         = null;
    period_id    = null;
    local        = null;
    observations = null;

}
