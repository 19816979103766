import { BaseModel } from 'modules/core/models/base-model';

const ACTIVE   = 1;
const INACTIVE = 2;
const PENDING  = 3;

export class UserStatus extends BaseModel {
    static get PENDING() {
        return PENDING;
    }

    static get ACTIVE() {
        return ACTIVE;
    }

    static get INACTIVE() {
        return INACTIVE;
    }
}
