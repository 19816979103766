import { inject }                 from 'aurelia-framework';
import { User }                   from 'modules/administration/models/user';
import { UserStatus }             from 'modules/administration/models/user-status';
import { RolesRepository }        from 'modules/administration/roles/services/repository';
import { UserGroupsRepository }   from 'modules/administration/user-groups/services/repository';
import { ClientsRepository }      from 'modules/wemake/clients/services/repository';
import { UserStatusesRepository } from 'modules/administration/user-statuses/services/repository';

@inject(RolesRepository, UserGroupsRepository, ClientsRepository, UserStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: UserStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param rolesRepository
     * @param userGroupsRepository
     * @param clientsRepository
     * @param userStatusesRepository
     */
    constructor(rolesRepository, userGroupsRepository, clientsRepository, userStatusesRepository) {
        this.rolesRepository        = rolesRepository;
        this.userGroupsRepository   = userGroupsRepository;
        this.clientsRepository      = clientsRepository;
        this.userStatusesRepository = userStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {User}
     */
    model() {
        let model = new User();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.name',
            size:  8,
        };

        this.email = {
            type:  'email',
            key:   'email',
            label: 'form.field.email',
            size:  4,
        };

        this.password = {
            type:  'password',
            label: 'form.field.password',
            size:  4,
            key:   'password',
        };

        this.password_confirmation = {
            type:  'password',
            key:   'password_confirmation',
            label: 'form.field.password-confirmation',
            size:  4,
        };

        this.roles = {
            type:         'multiselect-native',
            key:          'roles',
            label:        'form.field.roles',
            size:         4,
            remoteSource: this.rolesRepository.active.bind(this.rolesRepository),
        };

        this.groups = {
            type:         'multiselect-native',
            key:          'groups',
            label:        'form.field.groups',
            size:         4,
            remoteSource: this.userGroupsRepository.active.bind(this.userGroupsRepository),
            required:     false,
        };

        this.client_id = {
            type:         'select2',
            key:          'client_id',
            label:        'form.field.client',
            size:         4,
            remoteSource: this.clientsRepository.active.bind(this.clientsRepository),
            hidden:       !viewModel.appContainer.authenticatedUser.isSystemReserved(),
            required:     false,
        };

        this.company_organizations = {
            type:                   'fancytree',
            key:                    'company_organizations',
            label:                  'form.field.company-organization',
            size:                   12,
            remoteSource:           this.clientsRepository.companyOrganizationTree.bind(this.clientsRepository),
            remoteSourceParameters: () => viewModel.model.client_id,
            hidden:                 isEmpty(viewModel.model.client_id),
            settings:               {
                repository: this.companyOrganizationsRepository,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.userStatusesRepository.active.bind(this.userStatusesRepository),
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.users.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.name, this.email],
            [this.password, this.password_confirmation],
            [this.roles, this.groups, this.client_id],
            [this.company_organizations],
            [this.status_id],
            [this.buttons],
        ];
    }

}
