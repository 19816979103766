import { bindable, inject }  from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { DialogController }  from 'aurelia-dialog';
import { UsersRepository }   from 'modules/administration/users/services/repository';
import { FormSchema }        from 'modules/administration/users/auto-login/form-schema';

@inject(AppContainer, DialogController, UsersRepository, FormSchema)
export class AutoLoginModal extends BaseFormViewModel {

    @bindable modalSettings = {
        title:   'form.title.auto-login',
        buttons: false,
        isChild: false,
    };

    alert = {};

    autologin_link = null;

    autologinToken = {
        limit_to_ip: '',
    };

    copyLinkButtonRef = null;

    popovers = new Map();

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.manage',
            'administration.users.edit',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        this.model = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.user = response;

            this.modalSettings.subtitle = this.user.name;

            this.schema = this.formSchema.schema(this);

            this.model.limit_to_ip = this.user.limit_to_ip;

            if (this.user.token && this.user.signature) {
                this.autologin_link = this.appContainer.router.generate('auto-login', {token: this.user.token, signature: this.user.signature}, {absolute: true});
            }
        });
    }

    /**
     * Generates a new link
     */
    generateNewLink() {
        this.repository.createAutoLoginToken(this.user.id, this.model).then((response) => {
            if (response.status) {
                this.alert = {};

                this.autologin_link = this.appContainer.router.generate('auto-login', {token: response.model.token, signature: response.model.signature}, {absolute: true});

                // notify success
                this.appContainer.notifier.successNotice(response.message);
            } else {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);
            }
        });
    }

    /**
     * Deletes the link
     */
    deleteLink() {
        this.repository.deleteAutoLoginToken(this.user.id).then((response) => {
            if (response.status) {
                this.alert = {};

                this.autologin_link = null;

                // notify success
                this.appContainer.notifier.successNotice(response.message);
            } else {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);
            }
        });
    }

    /**
     * Copies the link
     */
    copyLink() {
        navigator.permissions.query({name: 'clipboard-write'}).then(result => {
            if (result.state === 'granted' || result.state === 'prompt') {
                navigator.clipboard.writeText(this.autologin_link).then(() => {
                    if (!this.popovers.has(this.user.id)) {
                        $(this.copyLinkButtonRef).addClass('btn-success');
                        $(this.copyLinkButtonRef).popover({
                            container: 'body',
                            placement: 'left',
                            title:     this.appContainer.i18n.tr('form.field.link-copied-to-clipboard'),
                            trigger:   'manual',
                        });

                        $(this.copyLinkButtonRef).popover('show');

                        this.popovers.set(this.user.id, setTimeout(() => {
                            $(this.copyLinkButtonRef).popover('hide');
                            $(this.copyLinkButtonRef).removeClass('btn-success');
                            this.popovers.delete(this.user.id);
                        }, 1250));
                    }
                });
            }
        });
    }

}
