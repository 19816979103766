import { bindable, inject } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { DialogController } from 'aurelia-dialog';

@inject(AppContainer, DialogController)
export class TreeModal {

    @bindable treeOptions;
    @bindable modalSettings = {
        title:   'form.title.tree',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     */
    constructor(appContainer, dialogController) {
        this.appContainer     = appContainer;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    activate(treeOptions) {
        this.treeOptions = treeOptions;

        // defines `activate` callback from treeOptions `onClick` callback
        this.treeOptions.activate = (event, data) => {
            this.treeOptions.onClick(event, data);

            this.dialogController.ok();
        };
    }

}
