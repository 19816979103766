import { bindable, inject }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseViewModel }             from 'base-view-model';
import { DialogController }          from 'aurelia-dialog';
import { DiplomaSettingsRepository } from 'modules/legislation/management/diploma-settings/services/repository';
import { Downloader }                from 'resources/services/downloader';

@inject(AppContainer, DialogController, Downloader, DiplomaSettingsRepository)
export class CropUserAvatar extends BaseViewModel {

    formId = 'administration.user-avatars.creation-form';

    @bindable repository;
    @bindable settings = {
        title: 'form.title.crop',
        size:  'modal-md',
    };

    imageCropper;
    croppedImage;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param downloader
     * @param repository
     */
    constructor(appContainer, dialogController, downloader, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.downloader       = downloader;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return true;
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.model = params.model;
        this.file  = params.file;
    }

    /**
     * Submits view form
     */
    submit() {
        let dataUrl = this.imageCropper.getImageDataURL();

        return this.repository.uploadReportLogo({file: dataUrl}).then(response => {
            if (response.status === true) {
                this.appContainer.notifier.successNotice(response.message);

                this.dialogController.ok({dataUrl: dataUrl, id: response.file.id});
            } else {
                this.alert = this.alertMessage(response.status, response.message, response.errors);
            }

            return response.status;
        });
    }

}
