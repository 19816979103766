import { inject } from 'aurelia-framework';
import { I18N }   from 'aurelia-i18n';

@inject(I18N)
export class DateRepository {

    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Returns week days
     *
     * @returns {*}
     */
    weekDays() {
        return Promise.resolve([
            {
                id:   1,
                name: this.i18n.tr('week-days.monday'),
            },
            {
                id:   2,
                name: this.i18n.tr('week-days.tuesday'),
            },
            {
                id:   3,
                name: this.i18n.tr('week-days.wednesday'),
            },
            {
                id:   4,
                name: this.i18n.tr('week-days.thursday'),
            },
            {
                id:   5,
                name: this.i18n.tr('week-days.friday'),
            },
            {
                id:   6,
                name: this.i18n.tr('week-days.saturday'),
            },
            {
                id:   7,
                name: this.i18n.tr('week-days.sunday'),
            },
        ]);
    }

    /**
     * Returns months
     *
     * @returns {*}
     */
    months() {
        return Promise.resolve([
            {
                id:   1,
                name: this.i18n.tr('months.january'),
            },
            {
                id:   2,
                name: this.i18n.tr('months.february'),
            },
            {
                id:   3,
                name: this.i18n.tr('months.march'),
            },
            {
                id:   4,
                name: this.i18n.tr('months.april'),
            },
            {
                id:   5,
                name: this.i18n.tr('months.may'),
            },
            {
                id:   6,
                name: this.i18n.tr('months.june'),
            },
            {
                id:   7,
                name: this.i18n.tr('months.july'),
            },
            {
                id:   8,
                name: this.i18n.tr('months.august'),
            },
            {
                id:   9,
                name: this.i18n.tr('months.september'),
            },
            {
                id:   10,
                name: this.i18n.tr('months.october'),
            },
            {
                id:   11,
                name: this.i18n.tr('months.november'),
            },
            {
                id:   12,
                name: this.i18n.tr('months.december'),
            },
        ]);
    }
}
