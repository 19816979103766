import { User }          from 'modules/administration/models/user';
import { UserStatus }             from 'modules/administration/models/user-status';
import { RolesRepository }        from 'modules/administration/roles/services/repository';
import { UserStatusesRepository } from 'modules/administration/user-statuses/services/repository';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: UserStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param rolesRepository
     * @param userStatusesRepository
     */
    constructor(rolesRepository, userStatusesRepository) {
        this.rolesRepository        = rolesRepository;
        this.userStatusesRepository = userStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {User}
     */
    model() {
        let model = new User();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       8,
            attributes: {
                disabled: true,
            },
        };

        this.email = {
            type:       'text',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            attributes: {
                disabled: true,
            },
        };

        this.role_id = {
            type:         'select2',
            key:          'role_id',
            label:        'form.field.role',
            size:         4,
            remoteSource: this.rolesRepository.active.bind(this.rolesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.userStatusesRepository.active.bind(this.userStatusesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('administration.users.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.name, this.email],
            [this.role_id],
            [this.status_id],
            [this.buttons],
        ];
    }

}
