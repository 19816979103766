import { BaseModel } from 'modules/core/models/base-model';

const GLOBAL_ADMINISTRATOR      = 1;
const JURIST                    = 2;
const CONSULTANT                = 3;
const LEGISLATION_ADMINISTRATOR = 4;

export class Role extends BaseModel {

    name            = null;
    slug            = null;
    description     = null;
    system_reserved = null;
    status_id       = null;
    permissions     = [];

    static get GLOBAL_ADMINISTRATOR() {
        return GLOBAL_ADMINISTRATOR;
    }

    static get JURIST() {
        return JURIST;
    }

    static get CONSULTANT() {
        return CONSULTANT;
    }

    static get LEGISLATION_ADMINISTRATOR() {
        return LEGISLATION_ADMINISTRATOR;
    }

}
