import { bindable, containerless, customElement, inject } from 'aurelia-framework';
import { CropUserAvatar }                                 from 'modules/administration/custom-elements/user-avatar/crop-user-avatar/index';
import { DialogService }                                  from 'aurelia-dialog';

@containerless
@inject(DialogService)
@customElement('user-avatar')
export class UserAvatar {

    @bindable model;
    @bindable toView;
    input;

    elementId     = 'user-avatar-input-' + Math.floor(Math.random() * 65000);
    imageId       = 'user-avatar-image-' + Math.floor(Math.random() * 65000);
    defaultAvatar = 'assets/images/no-image-selected.png';

    /**
     * Constructor
     *
     * @param dialogService
     */
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    /**
     * Bind event
     */
    bind() {
        //
    }

    /**
     * Handles attached event
     */
    attached() {
        this.input = document.getElementById(this.elementId);
        this.image = document.getElementById(this.imageId);

        if (this.toView !== true) {
            this.addEventListeners();
        }
    }

    /**
     * Adds the event listener for the hidden file input
     */
    addEventListeners() {
        this.input.addEventListener('change', (event) => {
            let files = event.target.files;

            if (!files || files.length === 0) {
                return false;
            }

            let file  = files[0];
            let image = URL.createObjectURL(file);

            this.dialogService
                .open({model: {model: this.model, file: image}, viewModel: CropUserAvatar})
                .whenClosed(response => {
                    if (!response.wasCancelled) {
                        this.image.src             = response.output.dataUrl;
                        this.model.report_logo_id  = response.output.id;
                        this.model.temporary_photo = response.output.dataUrl;
                    }

                    // clear any previous input
                    this.input.value = '';
                });
        });
    }

    /**
     * Clears any existing photo
     */
    clearPhoto() {
        this.model.existing_report_logo = null;
        this.model.report_logo_id       = null;
    }

}
