import { bindable, inject }               from 'aurelia-framework';
import { AppContainer }                   from 'resources/services/app-container';
import { BaseViewModel }                  from 'base-view-model';
import { CompanyOrganizationsRepository } from 'modules/administration/company-organizations/services/repository';

@inject(AppContainer, CompanyOrganizationsRepository)
export class ListCompanyOrganizations extends BaseViewModel {

    headerTitle = 'listing.administration.company-organizations';
    listingId   = 'administration-company-organizations-listing';

    @bindable newRecordRoute = 'administration.company-organizations.create';

    @bindable repository;
    @bindable datatable;

    @bindable filterModel = {
        parent_id: null,
    };

    @bindable treeOptions = {
        click:    this.treeNodeClicked.bind(this),
        activate: this.treeNodeActivated.bind(this),
    };

    displayTree = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;

        this.treeOptions.repository = {
            source: this.repository,
            method: 'tree',
        };
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.company-organizations.manage',
            'administration.company-organizations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:   'resource.administration.company-organization',
            repository: this.repository,
            edit:       'administration.company-organizations.edit',
            destroy:    'administration.company-organizations.delete',
            destroyed:  (row) => this.rowDestroyed(row),
            actions:    [
                {
                    tooltip: 'form.button.view',
                    icon:    'icon-file-eye',
                    action:  (row) => this.redirectToRoute({
                        name:   'administration.company-organizations.view',
                        params: {id: row.id},
                    }),
                },
            ],
            options:    [],
            buttons:    [],
            selectable: true,
            sorting:    {
                column:    0,
                direction: 'asc',
            },
            columns:    [
                {
                    data:  'acronym',
                    name:  'company_organizations.acronym',
                    title: 'form.field.acronym',
                },
                {
                    data:  'name',
                    name:  'company_organizations.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Handles tree node click event
     *
     * @param event
     * @param data
     */
    treeNodeClicked(event, data) {
        let activeNode = data.tree.getActiveNode();

        if (activeNode === data.node) {
            activeNode.setFocus(false);
            activeNode.setActive(false);

            this.filterModel.parent_id = null;

            this.datatable.instance.reload();

            return false;
        }
    }

    /**
     * Handles tree node activate event
     *
     * @param event
     * @param data
     */
    treeNodeActivated(event, data) {
        this.filterModel.parent_id = data.node.key;

        this.datatable.instance.reload();
    }

    /**
     * Handles `row destroyed even`
     *
     * @param row
     */
    rowDestroyed(row) {
        this.appContainer.eventAggregator.publish('reload-fancy-tree', {
            id: this.treeOptions.id,
        });
    }

    /**
     * Toggles the tree visibility
     */
    toggleTree() {
        this.treeOptions.activatedKey = this.filterModel.parent_id;

        this.displayTree = !this.displayTree;
    }

}
