import { bindable, inject }    from 'aurelia-framework';
import { PLATFORM }            from 'aurelia-pal';
import { AppContainer }        from 'resources/services/app-container';
import { BaseListViewModel }   from 'base-list-view-model';
import { CountriesRepository } from 'modules/administration/countries/services/repository';

@inject(AppContainer, CountriesRepository)
export class ListCountries extends BaseListViewModel {

    listingId = 'administration-countries-listing';

    @bindable headerTitle = 'listing.administration.countries';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/list.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.countries.manage',
            'administration.countries.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.administration.country',
            repository:      this.repository,
            edit:            false,
            destroy:         false,
            destroySelected: false,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'countries.name',
                    title: 'form.field.name',
                },
                {
                    data:  'capital',
                    name:  'countries.capital',
                    title: 'form.field.capital',
                },
                {
                    data:  'country_code',
                    name:  'countries.country_code',
                    title: 'form.field.country-code',
                },
                {
                    data:  'iso_3166_2',
                    name:  'countries.iso_3166_2',
                    title: 'form.field.iso-3166-2',
                },
                {
                    data:  'iso_3166_3',
                    name:  'countries.iso_3166_3',
                    title: 'form.field.iso-3166-3',
                },
                {
                    data:  'currency',
                    name:  'countries.currency',
                    title: 'form.field.currency',
                },
                {
                    data:  'calling_code',
                    name:  'countries.calling_code',
                    title: 'form.field.calling-code',
                },
                {
                    data:  'flag',
                    name:  'countries.flag',
                    title: 'form.field.flag',
                    type:  'embedded-image',
                },
            ],
        };
    }

}
