import { inject }                      from 'aurelia-framework';
import { CustomHttpClient }            from 'resources/services/custom-http-client';
import { Role }                        from 'modules/administration/models/role';
import { BooleanStatusesRepository }   from 'modules/administration/boolean-statuses/services/repository';
import { PermissionActionsRepository } from 'modules/administration/permission-actions/services/repository';

@inject(CustomHttpClient, BooleanStatusesRepository, PermissionActionsRepository)
export class RolesRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param booleanStatusesRepository
     * @param permissionActionsRepository
     */
    constructor(httpClient, booleanStatusesRepository, permissionActionsRepository) {
        this.httpClient                  = httpClient;
        this.booleanStatusesRepository   = booleanStatusesRepository;
        this.permissionActionsRepository = permissionActionsRepository;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/roles/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/roles/active');
    }

    /**
     * Returns all users with a given role
     *
     * @returns {*}
     */
    allUsers(roleId) {
        return this.httpClient.get('administration/roles/' + roleId + '/all-users');
    }

    /**
     * Returns active users with a given role
     *
     * @returns {*}
     */
    activeUsers(roleId) {
        return this.httpClient.get('administration/roles/' + roleId + '/active-users');
    }

    /**
     * Returns global administrator users
     *
     * @returns {*}
     */
    globalAdministrators(all = false) {
        let roleId = Role.GLOBAL_ADMINISTRATOR;

        return all ? this.allUsers(roleId) : this.activeUsers(roleId);
    }

    /**
     * Returns consultant users
     *
     * @returns {*}
     */
    consultants(all = false) {
        let roleId = Role.CONSULTANT;

        return all ? this.allUsers(roleId) : this.activeUsers(roleId);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('administration/roles', data);
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/roles/search', criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get('administration/roles/' + id);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put('administration/roles/' + id, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete('administration/roles/' + id);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('administration/roles/destroy-selected', {ids: ids});
    }

    /**
     * Fetches active boolean statuses from server
     *
     * @returns {*}
     */
    activeBooleanStatuses() {
        return this.booleanStatusesRepository.active();
    }

    /**
     * Fetches active permission actions from server
     *
     * @returns {*}
     */
    activePermissionActions() {
        return this.permissionActionsRepository.active();
    }

    /**
     * Fetches active resources with permission from server
     *
     * @param roleId
     */
    activeResourcesWithPermissions(roleId) {
        return this.httpClient.get('administration/roles/' + roleId + '/resources/active-with-permissions');
    }
}
