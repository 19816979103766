import { inject }                 from 'aurelia-framework';
import { BaseFilterFormSchema }   from 'resources/classes/base-filter-form-schema';
import { LocalStorage }           from 'resources/services/local-storage';
import { EventAggregator }        from 'aurelia-event-aggregator';
import { I18N }                   from 'aurelia-i18n';
import { RolesRepository }        from 'modules/administration/roles/services/repository';
import { ClientsRepository }      from 'modules/wemake/clients/services/repository';
import { UserStatusesRepository } from 'modules/administration/user-statuses/services/repository';

@inject(LocalStorage, EventAggregator, I18N, RolesRepository, ClientsRepository, UserStatusesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param rolesRepository
     * @param clientsRepository
     * @param userStatusesRepository
     */
    constructor(storage, eventAggregator, i18n, rolesRepository, clientsRepository, userStatusesRepository) {
        super(storage, eventAggregator, i18n);

        this.rolesRepository        = rolesRepository;
        this.clientsRepository      = clientsRepository;
        this.userStatusesRepository = userStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Client}
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns client data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:     'text',
            key:      'name',
            label:    'form.field.name',
            size:     4,
            required: false,
        };

        this.email = {
            type:     'text',
            key:      'email',
            label:    'form.field.email',
            size:     4,
            required: false,
        };

        this.roles = {
            type:         'multiselect-native',
            key:          'roles',
            label:        'form.field.role',
            size:         4,
            remoteSource: this.rolesRepository.all.bind(this.rolesRepository),
            required:     false,
        };

        this.statuses = {
            type:         'multiselect-native',
            key:          'statuses',
            label:        'form.field.status',
            size:         4,
            remoteSource: this.userStatusesRepository.all.bind(this.userStatusesRepository),
            required:     false,
        };

        this.clients = {
            type:         'multiselect-native',
            key:          'clients',
            label:        'form.field.client',
            size:         4,
            remoteSource: this.clientsRepository.all.bind(this.clientsRepository),
            hidden:       viewModel.appContainer.authenticatedUser.hasCurrentTenant(),
            required:     false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.name, this.email, this.roles],
            [this.clients, this.statuses],
            [this.buttons],
        ];
    }

}
