import { inject }            from 'aurelia-framework';
import { AppContainer }      from 'resources/services/app-container';
import { BaseFormViewModel } from 'base-form-view-model';
import { RolesRepository }   from 'modules/administration/roles/services/repository';
import { FormSchema }        from 'modules/administration/roles/form-schema';

@inject(AppContainer, RolesRepository, FormSchema)
export class EditRole extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'administration.roles.create';
    formId         = 'administration.roles.edition-form';

    alert       = {};
    model       = {};
    schema      = {};
    permissions = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository     = repository;
        this.formSchema     = formSchema;
        this.newRecordRoute = appContainer.authenticatedUser.can(['administration.roles.manage', 'administration.roles.create']) ? this.newRecordRoute : null;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.roles.manage',
            'administration.roles.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.schema       = this.formSchema.schema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params);
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert             = null;
        this.model.permissions = this.permissions;

        this.repository
            .update(this.model.id, this.model)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.initialModel.assign(this.model);

                    this.redirectToRoute('administration.roles.index', 500);
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            }).catch(console.log.bind(console));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.repository.find(params.id).then((response) => {
                this.initialModel.assign(response);
                this.model.assign(response);

                this.model.permissions.forEach((permission) => {
                    this.permissions.push(permission.id);
                });
            }),
            this.repository.activeResourcesWithPermissions(params.id).then((response) => this.resources = response),
            this.repository.activePermissionActions().then((response) => this.permissionActions = response),
        ]);
    }

    /**
     *
     * @param permissions
     * @param actionId
     *
     * @returns {*}
     */
    permissionIdByResourcePermissionsAndAction(permissions, actionId) {
        let permissionId = null;

        permissions.forEach((permission) => {
            if (permission.action_id === actionId) {
                permissionId = permission.id;
            }
        });

        return permissionId;
    }

}
