import { BaseModel } from 'modules/core/models/base-model';

const PORTUGAL = 620;

export class Country extends BaseModel {

    static get PORTUGAL() {
        return PORTUGAL;
    }

}
