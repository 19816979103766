import { BaseModel } from 'modules/core/models/base-model';

export class CompanyOrganization extends BaseModel {

    parent_id = null;
    acronym   = null;
    name      = null;
    status_id = null;
    users     = [];

}
